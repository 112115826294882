import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import Image from '../Image'
// import Button from '../Inlines/Button'
import styles from './blog-hero.module.sass'

export default function BlogHero({ children }) {
  const { imageSharp } = useStaticQuery(graphql`
    query {
      imageSharp(fluid: { originalName: { eq: "software-development-blog.png" } }) {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  `)

  return (
    <section className={styles.blogHero}>
      <div className="container">
        <div className="row">
          <div className={classNames(styles.heroEntry, 'col-md-7')}>
            <h1 className={styles.heroTitle}>SoftKraft Blog</h1>
            <h2 className={styles.heroHeading}>
              We continuously <em>learn new things and share</em> those things with you here
            </h2>
            {/* <Button to="">All Topics</Button> */}
            {children}
          </div>
          <div className={classNames(styles.heroAsset, 'col-md-5')}>
            <Image
              alt="Blog hero image"
              src="/images/pages/blog/software-development-blog.png"
              fluid={imageSharp && imageSharp.fluid}
              className={styles.heroImg}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
