import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'

import Layout from '../layout'
import Section from '../components/Section'
import CardHorizontal from '../components/CardHorizontal'
import Pagination from '../layout/Pagination'
import PageHead from '../layout/PageHead'
import PageHeader from '../components/Sections/PageHeader'
import BlogHero from '../components/Blog/BlogHero'

export default function BlogPage({ data: { posts }, pageContext: { currentPage, numPages } }) {
  const page = {
    title: `SoftKraft - Blog`,
    description: `SoftKraft - Blog`,
    path: currentPage > 1 ? `/blog/page/${currentPage}/` : `/blog/`
  }

  return (
    <Layout path={'/blog/'}>
      <PageHead {...page} />
      <PageHeader dark={false} />
      <BlogHero />
      <Section classes="blog bg-light py-5 my-0" style={{ borderTop: '1px solid #E7EAF0' }}>
        {posts.nodes.map(post => (
          <CardHorizontal key={post.path} {...post}>
            <Markdown
              options={{ wrapper: 'h2', forceWrapper: true }}
              children={post.title || ''}
            />
            <p>{post.description}</p>
          </CardHorizontal>
        ))}
      </Section>
      {numPages && (
        <Section classes="pt-0 bg-light">
          <Pagination
            currentPage={currentPage}
            totalPages={numPages}
            hideEllipsis={true}
            boundaryPagesRange={99}
            hidePreviousAndNextPageLinks={true}
            hideFirstAndLastPageLinks={true}
          />
        </Section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query Blog($limit: Int, $skip: Int) {
    posts: allPostsYaml(limit: $limit, skip: $skip, sort: { fields: [date], order: DESC }) {
      totalCount
      nodes {
        path
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumb
        image
        tag
      }
    }
  }
`
