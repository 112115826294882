import React from 'react'
import kebabCase from 'lodash/kebabCase'
import { Link } from 'gatsby'

export default ({ path, thumb, image, title, children, tag }) => (
  <div key={path} className="row mb-4 mb-lg-5">
    <div className="col-lg-6">
      <Link to={path} className="link-unstyled" rel="nofollow">
        <img
          src={thumb || image}
          alt={title}
          className={'rounded'}
          style={{
            width: '100%',
            objectFit: 'cover'
          }}
        />
      </Link>
    </div>
    <div className="col-lg-6 col-md-12 pl-lg-3">
      <div className="row pl-lg-2 mb-2">
        {tag && (
          <Link
            to={`/tags/${kebabCase(tag)}/`}
            key={tag + `tag`}
            className="tag ml-3 my-2 ml-md-2 my-md-0 px-3 mb-md-1 bg-primary text-white font-weight-bold link-unstyled"
            rel="nofollow"
          >
            {tag}
          </Link>
        )}
      </div>
      <Link to={path} className="link-unstyled" rel="nofollow">
        {children}
      </Link>
    </div>
  </div>
)
