import React from 'react'
import { Link } from 'gatsby'
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination'

const Page = ({ value, isActive, onClick }) => (
  <li className={isActive ? 'page-item active' : 'page-item'}>
    <Link
      to={value === 1 ? `/blog/` : `/blog/page/${value}/`}
      className="page-link"
      onClick={onClick}
    >
      {value}
    </Link>
  </li>
)

const WrapperComponent = ({ children }) => {
  return (
    <ul className="pagination pagination-lg justify-content-center">
      {children}
    </ul>
  )
}

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
}

const Pagination = createUltimatePagination({
  itemTypeToComponent,
  WrapperComponent,
})

export default Pagination
